/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h3, null, "Mixing programming with printing giving you a custom, one-of-one design with every order (or pretty close to that)"), "\n", React.createElement(_components.p, null, "I have always wanted to try dropshipping a product on Shopify. There are plenty of tools that, once installed, you need to maintain and market the site. All-in-one solutions seemed boring to me. I wanted to sell something unique by adding some programming to it."), "\n", React.createElement(_components.p, null, "I saw several GitHub heatmap implementations a few times in a single day. One of them was a GitHub heatmap on a shirt. It wasn't my heatmap; it could have been anyones. There was no customization, so all of them looked the same. This idea stuck and what I was going to build. Let people add their GitHub heatmap onto a shirt in a theme they like in the shape of their favorite language."), "\n", React.createElement(_components.p, null, "I created a template to programmatically change each \"pixel's\" color in the design. As I went through the integration, these templates changed until I found something that was repeatable and easy to create new designs. Once the art was in a good place, I started working to integrate Printful with Shopify. I had to use something other than Printful's Shopify plugin since the designs needed to be predefined. I needed something else for my idea since every shirt was unique. I wrote my own integration between Printful and Shopify to create templates on the fly, complete the purchase on Printful and send me alerts along the way. It was a pain in the ass to have to do, but it allowed me to offer the product I wanted."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
